<template>
  <header class="flex align-item-center mrj-header__root">
    <div class="mrj-header__root-l-wrap flex align-item-center esd" v-if="isnavsFold">
      <div class="menu cursor-pointer" @mouseenter="handleShowDrawer">
        <div v-for="(item, index) in 9" :key="index"></div>
      </div>
      <slot v-if="Object.keys($slots).includes('customLogo')" name="customLogo"></slot>
      <div v-else-if="isIndex" class="height100 mrj-header__root-l-wrap-index flex justify-center align-item-center"
        style="padding: 0 38px" @click="isDrawer = true">
        <img v-if="consoleInfo.in_logo" src="https://attachment.shenglisige.com/images/dongling-inlogo.png"
          class="cursor-pointer" style="height: 32px; filter: grayscale(100%) brightness(5)" alt="pro-overview-logo" />
      </div>
      <div v-else class="flex-1 mrj-header__root-l-wrap-brand-info flex align-item-center cursor-pointer"
        :style="!is_url_name ? 'padding: 0 38px' : 'width:180px;padding-left:38px'" @click="isDrawer = true">

        <img v-if="activeBrandInfo?.menu_title == '总览' || activeBrandInfo?.title == '总览'"
          style="height: 32px; width:auto" src="@/assets/images/slsglogo.png" alt="">
        <span v-else>
          <i v-if="activeBrandInfo.menu_icon" class="meiye-icon bg-n-icon" :class="activeBrandInfo.menu_icon"></i>
          <span class="mrj-header__root-l-wrap-brand-info-name">{{ activeBrandInfo.menu_title }}</span>
        </span>
      </div>
      <div class="mrj-header__root-l-wrap-arrow cursor-pointer" @click="handleShowDrawer"></div>
    </div>
    <div class="mrj-header__root-l-wrap flex align-item-center wid_69" v-else>
      <div class="menu cursor-pointer" @mouseenter="handleShowDrawer">
        <div v-for="(item, index) in 9" :key="index"></div>
      </div>
    </div>
    <div class="flex-1 mrj-header__root-r-wrap flex align-item-center ii">
      <template>
        <div class="mrj-layout-header-btn media-768">{{ url_name }}</div>
      </template>
      <a-input class="mrj-layout-header-search header-search media-768" placeholder="搜索框默认内容"
        @pressEnter="onSeach($event.target._value)">
        <template slot="prefix">
          <i class="meiye-icon meiye-sousuo" v-if="!getAssignTheme"></i>
          <i class="meiye-icon meiye-sousuo" v-else></i>
        </template>
      </a-input>
      <right-content style="margin-right: 14px" :top-menu="false" :theme="'light'" />
    </div>
    <drawer v-model="isDrawer" :showHandler="false" class="brand-drawer">
      <a-row>
        <img @click="isDrawer = !isDrawer" src="@/assets/images/back.svg"
          class="mrj-drawer-t-l-arrow-back font-14 weight-500 cursor-pointer" alt="返回" />
      </a-row>
      <!-- 经营方式 -->
      <div v-if="menuList.length">
        <div class="brand" v-for="(item, index) in menuList" :key="index + 'menu'" style="margin-top: 0"
          :class="'brand' + index">
          <div class="brand-title" v-if="item.title && item.title != '快捷入口'">{{ item.title }}</div>
          <div v-if="item.title != '快捷入口'" class="flex flex-wrap justify-between brand-wrap">
            <template>
              <div v-for="(it, id) in item.children" @click="handleBrandClick(it, 'img', id)" :key="id"
                class="width50 flex flex-column align-item-center brand-wrap-item cursor-pointer"
                :class="{ 'brand-wrap-item-active': activeBrandInfo.menu_title == it.title || hover == it.id }"
                @mouseover="hover = it.id" @mouseleave="hover = ''">
                <i class="meiye-icon brand-wrap-item-img" :class="it.icon"></i>
                <span class="brand-wrap-item-title ant-table-row-cell-ellipsis">{{ it.title || '--' }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </drawer>
  </header>
</template>

<script>
  import Drawer from './drawer'
  import RightContent from './RightContent'
  import storage from 'store'
  import axios from 'axios'
  import {
    mapGetters
  } from 'vuex'
  import {
    getHistory,
    setHistory
  } from '@/api/user'
  import Msg from '@/components/mrj-menu/msg'
  import microApp from '@micro-zoe/micro-app'
  export default {
    inject: ['reload'],
    components: {
      RightContent,
      Drawer
    },
    computed: {
      ...mapGetters(['consoleInfo']),
      // activeBrandInfo: {
      // get() {
      //   debugger
      //   let obj = storage.get('global_menu_obj') || { menu_title: '总览', menu_icon: 'meiye-gaikuangzonglan' }
      //   return obj
      // },
      // set(val) {
      //   console.log(val);
      // }
      // const industry = storage.get('industryData') || []
      // const { url } = this.$route.query
      // if (url) {
      //   return industry.filter((item) => item?.brand?.jump_to && url.includes(item?.brand?.jump_to))[0]?.brand || {}
      // } else {
      //   return storage.get('global_brand_obj') || {}
      // }
      // return storage.get('global_menu_obj')
      // },
      getAssignTheme() {
        // only dark default
        const {
          theme_color
        } = storage.get('global_brand_obj') || {}
        if (theme_color) {
          // return `mrj-header__theme-${theme_color}`
          return ''
        } else {
          return ''
        }
      },
      // ...mapGetters(["isnavsFold"])
      isnavsFold() {
        console.log(this.$store.state.isnavsFold);
        return this.$store.state.isnavsFold
      },
      // url_name: {
      //   get() {
      //     debugger
      //     return storage.get('global_brand_name') || '总览'
      //   },
      //   set(val) {
      //     console.log(val);
      //   }
      // },
    },
    props: {
      themeMode: {
        type: String,
        default: 'primary',
      },
      isIndex: {
        type: Boolean,
        default: false,
      },
      is_url_name: {
        type: Boolean,
        default: true,
      },
      // url_name: {
      //   type: String,
      //   default: '总览',
      // },
    },
    data() {
      return {
        menuList: storage.get('menu_base') || [],
        history: {
          component: "LayoutMicro",
          icon: "meiye-routeicon-home",
          id: 5555,
          params: "",
          path: "",
          pid: 0,
          redirect: "/headquarters/",
          title: "最近访问",
          children: []
        },
        hover: false,
        iconOrImg: "icon",
        logo_url: null,
        isDrawer: false,
        inputHover: false,
        url_name: localStorage.getItem('global_brand_name') || '总览',
        activeBrandInfo: storage.get('global_menu_obj') || {
          title: '总览'
        }
      }
    },
    created() {
      this.getHistoryFn()
      if (storage.get('seconderyMenuObj2') && storage.get('seconderyMenuObj2').menu_title) {
        this.url_name = storage.get('seconderyMenuObj2').title || storage.get('seconderyMenuObj2').menu_title
      } else {
        if (storage.get('seconderyMenuObj2') && storage.get('seconderyMenuObj2').title) {
          this.url_name = storage.get('seconderyMenuObj2').title
        } else {
          this.url_name = '总览'
        }
      }
      this.activeBrandInfo.menu_icon = storage.get('global_menu_obj')?.menu_icon || storage.get('menu_base')[0]
        .children[0]
      // this.url_name = storage.get('seconderyMenuObj') ? storage.get('seconderyMenuObj').title ? storage.get('seconderyMenuObj').title : '总览' : '总览'
      console.log('process.env :>> ', process.env);
      console.log(this.$route.path);
      let obj = this.$route.path
      let menu = storage.get('menu_base')
      //sandbox.share.huanlong.cloud
      if (this.$route.query.url && this.$route.query.url.includes('http')) {
        console.log(storage.get('global_menu_obj'));
      } else {
        menu.forEach(el => {
          if (el.children && el.children.length) {
            el.children.forEach(m => {
              if (obj.includes(m.path)) {
                // debugger
                storage.set('global_menu_obj', {
                  menu_id: m.id,
                  menu_icon: m.icon,
                  menu_title: m.title,
                  path: m.path,
                  fullpath: m.fullpath,
                  url: m.url,
                  menu_children: m.children ? m.children : undefined
                })
              }
            })
          }
        });
      }
    },
    methods: {
      // 最近访问
      getHistoryFn() {
        let data = {
          system_id: 2
        }
        getHistory(data).then(res => {
          if (res.error_code == 0) {
            this.history.children = res.data.menu
            this.menuList[2] = this.history
          }
          console.log(res.data);
        }).finally(r => {})
      },
      setHistoryFn(id) {
        let data = {
          permission_id: id,
          system_id: 2,
        }
        setHistory(data).then(res => {})
      },
      onSeach(text) {
        this.$router.push({
          path: '/search-result',
          query: {
            keyworeds: text
          },
        })
        let global_menu_obj = {
          "menu_id": 114,
          "menu_icon": "meiye-gaikuangzonglan",
          "menu_title": "总览",
          "path": "overview",
          "url": "",
          "title": "总览",
        }
        storage.set('global_menu_obj', global_menu_obj)
        storage.set('activeTabsKey', '总览')
        storage.set('seconderyMenuObj2', global_menu_obj)

        setTimeout(() => {
          Msg.$emit('changeRouterview', true)
        }, 10);

      },
      getExpiresTime(expiresTime) {
        let nowTimeNum = Math.round(new Date() / 1000)
        let expiresTimeNum = expiresTime - nowTimeNum
        return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
      },
      // UUMtoken登录
      tokentoLogin(e, brand) {
        let url = brand.url.split('/admin')[0]
        axios
          .post(url + '/adminapi/uum_login ', {
            authorization: e,
          })
          .then((res) => {
            let data = res.data.data
            let expires = this.getExpiresTime(data.expires_time)
            // 记录用户登陆信息
            // Cookies.set('uuid', data.user_info.id, { expires: expires || 1 })
            // Cookies.set('token', data.token, { expires: expires || 1 })
            // Cookies.set('expires_time', data.expires_time, { expires: expires || 1 })
            this.$router.push({
              path: brand.url_name,
              query: {
                nick_name: brand.nick_name,
                url: brand.url,
                url_name: brand.url_name,
              },
            })
          })
      },
      handleShowDrawer() {
        this.isDrawer = !this.isDrawer
        console.log(this.isDrawer)
      },
      async handleBrandClick(brand, type, index) {
        //点击一级菜单的时候 eg 报表 门店 财税 合伙人
        console.log('brand :>> ', brand)
        let global_menu_obj = {
          menu_id: brand.id,
          path: brand.path,
          menu_icon: brand.icon,
          menu_title: brand.title,
          url: brand.url,
          menu_children: brand.children ? brand.children : undefined
        }
        if (brand.children && brand.children.length) {
          let seconderyMenuObj = {
            menu_id: brand.children[0].id,
            path: brand.children[0].path,
            menu_icon: brand.children[0].icon,
            menu_title: brand.children[0].title,
            fullPath: '/' + brand.children[0].path,
            fullpath: brand.children[0].path,
            url: brand.children[0].url,
            menu_children: brand.children[0].children ? brand.children[0].children : undefined
          }
          seconderyMenuObj.fullPath = seconderyMenuObj.fullPath.replace('//', '/')
          storage.set('seconderyMenuObj2', seconderyMenuObj)
          if (brand.title == '合伙人') {
            storage.set('seconderyMenuObj', seconderyMenuObj)
          }
        } else {
          storage.set('seconderyMenuObj2', {})
        }
        this.activeBrandInfo = global_menu_obj
        this.activeBrandInfo.menu_icon = brand.icon
        this.url_name = brand.children ? brand.children[0].title : brand.title
        this.activeBrandInfo.menu_title = brand.title
        this.activeBrandInfo.menu_icon = brand.icon
        storage.set('global_menu_obj', global_menu_obj)
        storage.set('activeTabsKey', this.url_name)

        // activeIndex 恢复成0
        localStorage.setItem('activeIndex', 0)
        storage.set('thirdMenuObj', '')
        if (brand.title == '总览') {
          this.$router.replace('/overview')
          this.isDrawer = false
          //延迟刷新，解决刷新后点击总览页面不更新
          setTimeout(() => {
            Msg.$emit('changeRouterview', true)
          }, 10);
          return false
        } else {
          if (brand.title == '组织' || brand.title == '产品' || brand.title == '运营中心') {
            let url = brand.children ? ('/') + brand.children[0].path : ('/' + brand.path)
            url = url.replace('//', '/')
            this.setHistoryFn(brand.id)
            this.$router.replace({
              path: url,
              query: {
                url: brand.url,
                nick_name: 'education'
              }
            })
          } else {
            if (brand.title == '连锁商品') {
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "产品列表",
                  "fullPath": "/shop/management/managementlist"
                }, {
                  "title": "赠送产品",
                  "fullPath": "/shop/management/give"
                }],
                "title": "商品列表",
                "fullPath": "/shop/management"
              })
            }

            if (brand.title == '连锁商城') {
              storage.set('seconderyMenuObjshop', {
                "title": "概况",
                "fullPath": "/mall/malloverview"
              })
            }

            if (brand.title != '总览' && brand.title != '设置') {
              this.setHistoryFn(brand.id)
            }
            let basurl = ''
            if (brand.children && brand.children.length) {
              basurl = '/' + brand.path
            }
            //  url = brand.children ? brand.children[0].path : ('/' + brand.path)
            // console.log(brand.children[0].path);
            console.log(brand.path);
            basurl = basurl.replace('//', '/')
            this.$router.replace({
              path: '/headquarters/' + basurl,
              query: {
                url: brand.url,
                nick_name: 'education'
              }
            })
          }
          Msg.$emit('changeRouterview', true)
        }

        this.isDrawer = false
      },
    },
    mounted() {
      var _this = this
      Msg.$on('getpathname2', function(e) {
        console.log(e, 'getpathname2');
        _this.url_name = e.title
        let a = storage.get('global_menu_obj')
        _this.activeBrandInfo = a
      })
    },
  }
</script>

<style lang="less" scoped>
  .mrj-drawer-t-l-arrow-back {
    color: #33323f;
    height: 24px;
    width: 24px;
  }

  .mrj-drawer-t-l-arrow-back:hover {
    background: #f2f5f5;
    // opacity: 0.8;
    height: 24px;
    width: 24px;
    border-radius: 4px;
  }

  .mrj-header__root {
    position: relative;
    z-index: 99;
    // background: @layout-header-background;
    // background: linear-gradient(
    //   90deg,
    //   @topnavheadbgColor1 0%,
    //   @topnavheadbgColor2 57%,
    //   @topnavheadbgColor3 100%
    // );
    background: linear-gradient(90deg,
        rgba(41, 97, 122, 1) 0%,
        rgba(25, 106, 115, 1) 57%,
        rgba(5, 110, 100, 1) 100%);
    // linear-gradient(180deg, rgba(21, 27, 36, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    // box-shadow: 0px 2px 5px 0px #A3A8CA;
    // margin-bottom: 2px;
    opacity: 1;

    .brand-drawer /deep/ .content {
      padding: 25px 23px;
      width: 180px;
      border-right: 1px solid #c9ccd9;
      box-sizing: border-box;
      background: #ffffff;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    &::after {
      // box-shadow: 0px 2px 0px 0px #3c5fce;
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0;
      height: 2px;
      // background: linear-gradient(
      //   61deg,
      //   @topnavheadbottombgColor1 0%,
      //   @topnavheadbottombgColor2 100%
      // );
      background: linear-gradient(61deg, #145a54 0%, #134b63 100%);
      background: linear-gradient(270deg, #145a54 0%, #134b63 100%);
    }

    &-l-wrap {
      position: relative;
      height: 52px;
      border-right: 1px solid @dvisionColor;

      &-index {
        // width: 180px;
        box-sizing: border-box;
        position: relative;
        z-index: 6;
      }

      &-arrow {
        position: absolute;
        z-index: 12;
        right: 11px;
        bottom: 0px;

        // width: 10px;
        // height: 10px;
        &::before {
          position: absolute;
          display: block;
          opacity: 1;
          left: -1px;
          bottom: 3px;
          content: "";
          width: 12px;
          height: 12px;
          background: url(../../assets/images/mrj-jiaobiao-default.png) no-repeat 0 0;
          background-size: 100% 100%;
        }

        &:hover::before {
          position: absolute;
          display: block;
          opacity: 1;
          left: -1px;
          bottom: 3px;
          content: "";
          width: 12px;
          height: 12px;
          background: url(../../assets/images/mrj-jiaobiao-hover.png) no-repeat 0 0;
          background-size: 100% 100%;
        }

        &:active::before {
          position: absolute;
          display: block;
          opacity: 1;
          left: -1px;
          bottom: 3px;
          content: "";
          width: 12px;
          height: 12px;
          background: url(../../assets/images/mrj-jiaobiao-click.png) no-repeat 0 0;
          background-size: 100% 100%;
        }
      }

      &-brand-info {

        // width: 180px;
        // margin-left: 36px;
        & img {
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }

        & i {
          // color: @btnMainInsertDColor;
          color: #2c6881;
          border-radius: 4px;
          // font-size: 16px;
          font-size: 22px;
          font-weight: 500;
          height: 22px;
          width: 22px;
          line-height: 22px;
          text-align: center;
          // background: linear-gradient(180deg, #ffffff 0%, #ebf7ff 100%);
          background: linear-gradient(180deg, #ffffff 0%, #ebf7ff 100%);
        }

        & i:hover {
          // color: @primary-color;
        }

        &-name {
          margin-left: 10px;
          color: #ffffff;
          font-size: 20px;
          font-weight: 540;
          margin-top: -2px;
        }
      }

      & .menu {
        position: absolute;
        left: 0;
        top: 17px;
        z-index: 12;
        display: flex;
        flex-wrap: wrap;
        height: 19px;
        width: 19px;
        justify-content: space-between;
        align-content: space-between;
        margin-right: 20px;

        div {
          width: 5px;
          height: 5px;
          background: @squaredninebgColor;
          box-shadow: inset 0px 1px 0px 0px @squarednineinsertColor;
          border-radius: 1px;
        }
      }
    }

    &-r-wrap {
      height: 52px;

      & .mrj-layout-header-btn {
        margin-left: 17px;
        font-size: 14px;
      }

      & .header-search {
        margin: auto;
        // margin-right: 12vw;
        width: 550px;
        background: @topnavsearchbgColor;
        border-radius: 6px;
        border: 1px solid @topnavsearchborderColor;

        & /deep/ input {
          &::placeholder {
            color: @topnavsearchfontColor !important;
          }

          &:hover {
            &::placeholder {
              color: @topnavsearchfontHColor !important;
            }

            color: @fontColor1 !important;
          }
        }

        & /deep/ input:hover,
        & /deep/ input:focus {
          box-shadow: none;

          // background: @topnavsearchbgHColor;
          // border: 1px solid #3c5fce;
          // color: #33323f;
          &::-webkit-input-placeholder {
            // color: @topnavsearchfontColor;
          }
        }

        & /deep/ .anticon {
          display: none;
          color: @topnavsearchiconColor;
        }

        & /deep/ input {
          height: 34px;
          color: #e9ecfc;
          background: transparent;
          box-shadow: none;
          border: none;
          font-size: 14px;
          border-radius: 6px;
          font-weight: 400;

          // border: 1px solid #3c5fce;
          &::-webkit-input-placeholder {
            // color: rgba(233, 236, 252, 0.73);
          }
        }

        .meiye-sousuo {
          font-size: 14px;
          color: @topnavsearchiconColor;
        }
      }
    }

    .brand {
      margin-top: 15px;

      &-title {
        margin-top: 22px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: @fontColor4;
        line-height: 17px;
      }

      &-wrap {
        &-items {
          margin-top: 14px;
          padding: 4px 15px 0px;
          color: #7b839c;

          &-imgs {
            width: 30px;
            height: 30px;
            background: #f8f8fa;
            border-radius: 6px;
            font-size: 20px;
            text-align: center;
            line-height: 30px;
            // opacity: 0.41;
          }

          &-titles {
            margin-top: 4px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #747382;
          }
        }

        &-item {
          margin-top: 14px;
          // margin-bottom: 7px;
          padding: 4px 15px 0px;

          &-img {
            width: 30px;
            height: 30px;
            background: #f2f9f8;
            border-radius: 6px;
            font-size: 24px;
            text-align: center;
            line-height: 30px;
            // color: #557e7b;
            color: #566665;
            // opacity: 0.41;
          }

          &-title {
            margin-top: 4px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: @fontColor4;
          }
        }
      }
    }

    .brand-wrap-item:hover {
      background: none;
    }

    .brand-wrap-item-active {

      .brand-wrap-item-img,
      .brand-wrap-items-imgs {
        background: @primaryColor;
        color: #ffffff;
      }

      .brand-wrap-item-title,
      .brand-wrap-items-titles {
        font-weight: 800;
        color: @baseColor11 !important;
      }
    }
  }

  .mrj-header__theme-dark {
    background: #242836;

    .brand-drawer {
      /deep/ .content {
        padding: 25px 23px;
        width: 180px;
        border-right: 1px solid #3b3e45;
        box-sizing: border-box;
        background: #3b3e45;

        &::-webkit-scrollbar {
          width: 0px;
        }
      }

      /deep/ .brand-wrap-item-title {
        color: #b8b8b8;
      }

      .brand-title,
      .mrj-drawer-t-l-arrow-back {
        color: #ffffff;
        background: #e0e0e9;
      }
    }

    &-l-wrap {
      &-brand-info {
        width: 180px;

        // margin-left: 36px;
        & img {
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }

        & i {
          font-size: 24px;
          color: #2851d3;
          border-radius: 4px;
          height: 22px;
          line-height: 22px;
          background: linear-gradient(180deg, #ffffff 0%, #ebf7ff 100%);
        }

        & i:hover {
          color: @primary-color;
        }

        &-name {
          margin-left: 10px;
          color: #ffffff;
          font-size: 22px;
        }
      }
    }

    &::after {
      box-shadow: 0px 1px 0px 0px #242527;
    }

    & .menu div {
      background: @squaredninebgColor;
      box-shadow: inset 0px -1px 0px 0px @squarednineinsertColor;
    }

    & .mrj-header__root-l-wrap {

      // border-right: 1px solid #001529;
      &-arrow::before {
        background: url(../../assets/images/mrj-jiaobiao-default-dark.png) no-repeat 0 0;
        background-size: 100% 100%;
      }

      &-arrow:hover::before {
        background: url(../../assets/images/mrj-jiaobiao-hover-dark.png) no-repeat 0 0;
        background-size: 100% 100%;
      }

      &-arrow:active::before {
        background: url(../../assets/images/mrj-jiaobiao-hover-dark.png) no-repeat 0 0;
        background-size: 100% 100%;
      }
    }

    .brand {
      margin-top: 15px;

      &-title {
        margin-top: 22px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #33323f;
        line-height: 17px;
      }

      &-wrap {
        &-items {
          margin-top: 12px;
          padding: 4px 15px 0px;
          color: #7b839c;

          &-imgs {
            width: 30px;
            height: 30px;
            background: #f8f8fa;
            border-radius: 6px;
            font-size: 20px;
            text-align: center;
            line-height: 30px;
            // opacity: 0.41;
          }

          &-titles {
            margin-top: 8px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #747382;
          }

          &-imgs:hover {
            background: #4b71e9;
            color: #ffffff;
          }

          &-titles.content {
            color: #4b71e9;
          }
        }

        &-items:hover {
          background: none;
        }

        &-item {
          margin-top: 12px;
          padding: 4px 15px 0px;
          color: #b8b8b8;
          opacity: 0.8;

          &-img {
            width: 30px;
            height: 30px;
            background: #f8f8fa;
            border-radius: 6px;
            font-size: 16px;
            text-align: center;
            line-height: 30px;
            // opacity: 0.41;
          }

          &-title {
            margin-top: 8px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 17px;
          }
        }

        &-item:hover {
          color: #eaebed;
          opacity: 1;
          background: none;

          &-img:hover {
            background: #636875;
          }

          &-title:hover {
            color: #eaebed;
          }
        }
      }
    }
  }

  .drawer-router-index {
    margin: 13px 0;
    padding: 0;

    & li {
      width: 134px;
      height: 24px;
      padding: 0 9px;
      line-height: 24px;
      background: #f6f7f8;
      border-radius: 6px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8b909d;
    }

    & li:hover {
      background: #f1f3f9;
      color: #535869;
    }
  }

  .header-search {
    &:hover {
      background: @topnavsearchbgHColor;
      border: 1px solid @topnavsearchborderHColor;

      .meiye-sousuo {
        font-size: 14px;
        color: @topnavsearchiconHColor;
      }
    }
  }

  .brand0 {
    .brand-wrap-item-img {
      background: @verticalnavigationbgDColor;
      color: #7b9c9a;
    }

    .brand-wrap-item-title {
      color: @fontColor5;
    }
  }

  .wid_69 {
    width: 69px;
    text-align: center;

    .menu {
      left: 25px !important;
    }
  }
</style>