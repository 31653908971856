<template>
  <div v-if="routesList && routesList.length" class="mrj-layout-brand-sider-wrap abbbb"
    :class="showLeft ? 'mrj-layout-brand-sider-wrap2' : ''">
    <ul class="mrj-layout-brand-sider height100" v-if="showLeft" :class="getAssignTheme">
      <template v-for="(it, ind) in routesList">
        <li :class="{ 'active-brand-sider-item': ind == activeIndex }" @click="goPages(it, ind)"
          class="flex align-item-center cursor-pointer" :key="it.id" v-if="true">
          <span style="height: 21px;" :class="{ 'active-brand-sider-text': ind == activeIndex }">
            <i class=" meiye-icon mr-13 font-26" :class="it.icon"></i>
          </span>
          <span class="nav-text inline-block flex align-item-center"
            :class="{ 'active-brand-sider-text': ind == activeIndex }">{{ it.title || it.name }}</span>
        </li>
      </template>
    </ul>
    <ul class="mrj-layout-brand-sider height100" style="width: 69px" :class="getAssignTheme" v-else>
      <template v-for="(it, ind) in routesList">
        <li :key="it.id" v-if="true" @click="goPages(it, ind)" class="menu-item flex align-item-center justify-center"
          style="padding: 0; width: 30px; margin: 7px 13px;">
          <span class="flex align-item-center justify-center "
            :class="{ 'active-brand-sider-text bg-fff': ind == activeIndex }">
            <a-tooltip placement="rightBottom" arrow-point-at-center>
              <template slot="title">
                <span>{{ it.title }}</span>
              </template>
              <i class=" meiye-icon cursor-pointer unfoldIcon" :class="it.icon"></i>
            </a-tooltip>
          </span>
        </li>
      </template>
    </ul>
    <div class="fold-btn" :class="showLeft ? '' : 'fold-btn-right'">
      <div :class="showLeft ? 'text-right' : 'text-center'">
        <a-icon :type="showLeft ? 'menu-fold' : 'menu-unfold'" @click="onfold()" />

      </div>
    </div>
  </div>
</template>

<script>
  // import { BrandMenu } from '@/api/login'
  import microApp from '@micro-zoe/micro-app'
  import storage from 'store'
  import Msg from '@/components/mrj-menu/msg'
  export default {
    inject: ['reload'],
    data() {
      return {
        activeIndex: localStorage.getItem('activeIndex') || 0,
        active_brand_id: null,
        showLeft: storage.get('showLeft') == false ? false : true,
        routesList: storage.get('global_menu_obj') ? storage.get('global_menu_obj').menu_children : [],
      }
    },
    computed: {
      activeName() {
        console.log('this.$route :>> ', this.$route)
        const {
          fullPath
        } = this.$route
        if (fullPath.indexOf('?nick_name') != -1) {
          let url = fullPath.split('?nick_name')[0]
          console.log('url :>> ', url)
          return url.split('/')[1]
        }
        console.log('fullPath :>> ', fullPath.split('/')[1])
        return fullPath.split('/')[1]
      },
      getAssignTheme() {
        // only dark default
        const {
          theme_color
        } = storage.get('global_brand_obj') || {}
        if (theme_color) {
          // return `mrj-layout-brand-sider-${theme_color}`
          return ''
        } else {
          return ''
        }
      },
      getfoldTheme() {
        // only dark default
        const {
          theme_color
        } = storage.get('global_brand_obj') || {}
        if (theme_color) {
          // return `fold-btn-${theme_color}`
          return ''
        } else {
          return ''
        }
      },

    },
    beforeCreate() {
      console.log('this.$route.query :>> ', this.$route.query);
      this.$router.push({
        query: {
          ...this.$route.query
        }
      });
    },
    created() {
      console.log(this.routesList, '/routesList');
    },
    methods: {
      onfold() {
        this.showLeft = !this.showLeft
        storage.set('showLeft', this.showLeft)
        this.$emit('onShowLeft', this.showLeft)
        this.$store.commit('setnavsFold', this.showLeft)
      },
      async goPages(it, index) {
        // console.log('点击侧边二级菜单 index :>> ', index);
        //点击侧边二级菜单
        this.activeIndex = index;
        localStorage.setItem('activeIndex', index)
        // console.log(this.$route.path);
        let a = this.$route.path.split('/')
        if (a[1] == 'headquarters') {
          a[1] = 'headquarters'
        } else if (a[1] == 'qyk') {
          a[1] = 'qyk'
        } else {
          a[1] = 0
        }
        console.log(a, 'a');
        let newobject = {
          children: it.children,
          component: it.component,
          icon: it.icon,
          id: it.id,
          params: it.params,
          path: it.path,
          pid: it.pid,
          title: it.title,
          fullPath: it.fullpath ? "/" + it.fullpath : "/" + it.path,
          url: it.url
        }
        newobject.fullPath = newobject.fullPath.replace('//', '/')
        storage.set('seconderyMenuObj2', newobject)





        let pushurl = ''
        if (newobject.children && newobject.children.length) {
          newobject.children.forEach((el, idx) => {
            newobject.children[idx].fullPath = newobject.children[idx].fullpath ? "/" + newobject.children[idx]
              .fullpath : "/" + newobject.children[idx].path
            newobject.children[idx].fullPath = newobject.children[idx].fullPath.replace('//', '/')
          })
          storage.set('activeTabsKey', newobject.children[0].title)
          storage.set('activeTabsKeyId', newobject.children[0].id)
          Msg.$emit('getpathname2', newobject)
          storage.set('global_brand_name', newobject.children[0].title)

          pushurl = pushurl = "/" + (a[1] ? a[1] : '') + '/' + it.children[0].path
          pushurl = pushurl.replace('//', '/')
          storage.set('thirdMenuObj', newobject.children[0])
          if (newobject.title == "权益卡") {
            pushurl = pushurl.replace('headquarters', 'qyk')
          }
          if (newobject.title == "制券中心") {
            pushurl = pushurl.replace('qyk', 'headquarters')
          }
          this.$router.replace({
            path: pushurl
            //  , query: { nick_name: 'hehuoren' }
          })
        } else {
          console.log(newobject)
          storage.set('thirdMenuObj', '')
          Msg.$emit('getpathname2', newobject)
          storage.set('activeTabsKey', newobject.title)
          storage.set('activeTabsKeyId', newobject.id)
          storage.set('global_brand_name', newobject.title)
          pushurl = "/" + (a[1] ? a[1] : '') + '/' + newobject.path
          pushurl = pushurl.replace('//', '/')
          if (newobject.title == "权益卡") {
            pushurl = pushurl.replace('headquarters', 'qyk')
          }
          if (newobject.title == "制券中心") {
            pushurl = pushurl.replace('qyk', 'headquarters')
          }
          if (newobject.title == "支付设置") {
            pushurl = pushurl.replace('/headquarters', '')
          }
          if (newobject.title == "支付设置") {
            pushurl = pushurl.replace('/headquarters', '')
          }
          if (newobject.path.includes('splitAccount')) {
            pushurl = pushurl.replace('/headquarters', '')
            pushurl = '/headquarters' + pushurl
          }
          if (newobject.path.includes('partner')) {
            pushurl = pushurl.replace('/headquarters', '')
          }

          if (storage.get('global_menu_obj').menu_title == '连锁商品' || storage.get('global_menu_obj').menu_title ==
            '商品中心') {
            if (newobject.title == '产品列表') {
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "产品列表",
                  "fullPath": "/shop/management/managementlist"
                }, {
                  "title": "赠送产品",
                  "fullPath": "/shop/management/give"
                }],
                "title": "商品列表",
                "fullPath": "/shop/management"
              })
            }

            if (newobject.title == '品牌管理') {
              storage.set('seconderyMenuObjshop', {
                "title": "品牌管理",
                "fullPath": "/shop/brand"
              })
            }

            if (newobject.title == '分类管理') {
              storage.set('activeTabsKey', "财务分类")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "财务分类",
                  "fullPath": "/shop/type/typeFinance"
                }, {
                  "title": "门店分类",
                  "fullPath": "/shop/type/typeStore"
                }, {
                  "title": "商城分类",
                  "fullPath": "/shop/type/typeMall"
                }],
                "title": "分类管理",
                "fullPath": "/shop/type"
              })
            }
            if (newobject.title == '服务列表') {
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "服务列表",
                  "fullPath": "/shop/service/servicelist"
                }, {
                  "title": "赠送服务",
                  "fullPath": "/shop/service/service_gifts"
                }],
                "title": "服务列表",
                "fullPath": "/shop/service"
              })
            }
          }


          if (storage.get('global_menu_obj').menu_title == '商城' || storage.get('global_menu_obj').menu_title == '连锁商城') {
            if (newobject.title == '概况') {
              storage.set('seconderyMenuObjshop', {
                "title": "概况",
                "fullPath": "/mall/malloverview"
              })
            }

            if (newobject.title == '商品列表') {
              storage.set('activeTabsKey', "已上架商品")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "已上架商品",
                  "fullPath": "/mall/mallshoplist/mallgrounding"
                }, {
                  "title": "已下架商品",
                  "fullPath": "/mall/mallshoplist/malloffshelf"
                }],
                "title": "商品列表",
                "fullPath": "/mall/mallshoplist"
              })
            }

            if (newobject.title == '商城订单') {
              storage.set('activeTabsKey', "销售单列表")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "销售单列表",
                  "fullPath": "/mall/mallorder/saleslip"
                }, {
                  "title": "取货记录",
                  "fullPath": "/mall/mallorder/selfpickupgoods"
                }, {
                  "title": "消耗单列表",
                  "fullPath": "/mall/mallorder/verificationservice"
                }, {
                  "title": "售后订单",
                  "fullPath": "/mall/mallorder/afterSales"
                }],
                "title": "商城订单",
                "fullPath": "/mall/mallorder"
              })
            }
            if (newobject.title == '商城用户') {
              storage.set('activeTabsKey', "用户列表")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "用户列表",
                  "fullPath": "/mall/mallusers/malluserslist"
                }, {
                  "title": "用户资产",
                  "fullPath": "/mall/mallusers/userassets"
                }],
                "title": "商城用户",
                "fullPath": "/mall/mallusers"
              })
            }
            if (newobject.title == '设置') {
              storage.set('activeTabsKey', "快递设置")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "快递设置",
                  "fullPath": "/mall/mallsetting/expresssettings"
                }, {
                  "title": "邀请码设置",
                  "fullPath": "/mall/mallsetting/invitationsettings"
                }],
                "title": "设置",
                "fullPath": "/mall/mallsetting"
              })
            }

            if (newobject.title == '运营管理') {
              storage.set('activeTabsKey', "小程序配置")
              storage.set('seconderyMenuObjshop', {
                "children": [{
                  "title": "小程序配置",
                  "fullPath": "/mall/operationmanagement/applet"
                }],
                "title": "运营管理",
                "fullPath": "/mall/operationmanagement"
              })
            }
          }



          this.$router.replace({
            path: pushurl
            // , query: { nick_name: 'hehuoren' }
          })
        }

      },
      toPage(it) {
        this.$emit('topName', it.name)
        this.$router.push({
          path: it.url_name,
          query: {
            nick_name: it.nick_name,
            url: it.url,
            url_name: it.url_name
          },
        })
      },

    },
    mounted() {
      var _this = this
      microApp.addDataListener('jixiao', function(e) {
        _this.activeIndex = e.activeIndex
      })

    },
    watch: {}
  }
</script>

<style lang="less" scoped>
  .mrj-layout-brand-sider-wrap {
    height: calc(100vh - 52px);
    width: 69px;
    // position: absolute;
    position: relative;
  }

  .mrj-layout-brand-sider-wrap2 {
    width: 181px;
  }

  .mrj-layout-brand-sider-wrap /deep/ .ant-spin-container {
    height: 100%;
  }

  .mrj-layout-brand-sider {
    position: relative;
    z-index: 10;
    padding: 8px 6px;
    width: 181px;
    background: @verticalnavigationbgDColor;
    box-shadow: 2px 2px 5px 0px #d4e8e6;
    border-right: 1px solid @verticalnavigationrightlineColor;
    color: #33323f;
    height: calc(100vh - 52px);
    position: fixed;
    top: 52px;
    left: 0;

    & li {
      margin: 7px 0;
      padding-left: 24px;
      height: 30px;

      & .nav-text {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: @verticalnavigationfontDColor;
      }

      & i {
        color: @verticalnavigationiconDColor;
      }
    }

    & li.active-brand-sider-item {
      background: @verticalnavigationbgAColor;
      border-radius: 4px;
    }

    & li span.active-brand-sider-text {
      color: @verticalnavigationfontHColor;

      & i {
        color: @verticalnavigationiconHColor;
      }
    }

    & li:not(.active-brand-sider-item):hover {
      background-color: @verticalnavigationbgHColor;
      border-radius: 4px;
    }
  }

  .mrj-layout-brand-sider-dark {
    position: relative;
    z-index: 1;
    padding: 8px 6px;
    width: 181px;
    background: #2a2c34;
    box-shadow: 2px 2px 5px 0px rgba(1, 1, 1, 0.12);
    border-right: 1px solid #212228;
    color: rgba(255, 255, 255, 0.6);

    & li {
      margin: 5px 0;
      padding: 6px 11px 6px 24px;

      & .nav-text {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }

    & li.active-brand-sider-item {
      background: #4b71e9;
      color: #ffffff;
      border-radius: 4px;
    }

    & li span.active-brand-sider-text {
      color: #ffffff;
    }

    & li:not(.active-brand-sider-item):hover {
      background-color: #4b71e9;
      border-radius: 4px;
    }
  }

  .fold-btn {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 38px;
    padding: 0 12px;
    line-height: 38px;
    font-size: 14px;
    font-weight: 800;
    color: #8a90b0;
    width: 180px;
    background: linear-gradient(157deg,
        @verticalnavigationbottomiconbgColor1 0%,
        @verticalnavigationbottomiconbgColor2 100%);

    i {
      color: @verticalnavigationbottomiconDColor;
    }

    i:hover {
      color: @verticalnavigationbottomiconHColor;
    }
  }

  .fold-btn-right {
    width: 68px;
  }

  .fold-btn-dark {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 38px;
    padding: 0 12px;
    line-height: 38px;
    font-size: 14px;
    font-weight: 800;
    color: #a9a9a9;
    width: 98%;
    background: linear-gradient(165deg, rgba(47, 51, 63, 0) 0%, #212327 100%);

    i:hover {
      color: #4b71e9;
    }
  }

  .mr-13 {
    margin-right: 10px;
  }

  .font-26 {
    font-size: 14px;
  }

  .bg-fff {
    padding: 0 14px;
    height: 30px;
    font-size: 16px;
    width: 30px;
    line-height: 30px;
    background: @verticalnavigationbgAColor;
    border-radius: 6px;
  }

  .unfoldIcon {
    font-size: 18px !important;
  }
</style>
