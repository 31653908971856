import { login, logout, queryUser, getCompany, pslogin } from '@/api/user'
import * as types from '@/store/types'
import storage from 'store'
const initState = {
  // 用来做是否已登录的标识，前端自己维护这个状态，登录成功后设置为 true，登出之后设置为 false
  logged: false,
  // 这里返回具体的权限点信息，然后通过 v-action 或者 $auth 进行权限判断
  permissions: [],
  // 用户名称
  // nickname: '',
  permission: [],
  token: storage.get('token') || '',
  staff_info: {
    company_id: '',
    user_id: "",
    staff_id: '',
    company_path: "",
    staff_mobile: '',
    staff_realname: "",
    staff_nickname: "",
    staff_avatar: "",
    staff_contacts_one: "",
    staff_contacts_two: "",
    staff_system_list: '',
    staff_status: '',
    staff_last_login_time: "",
    company_name: "",
    company_name_short: '',
    company_code: "",
  },


}

const user = {
  state: {
    ...initState
  },

  mutations: {
    SET_USER: (state, data) => {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value
        console.log(key, value, state[key]);
      })
      state.logged = storage.get('token') ? true : false
    },
    INIT_STATE: (state, data) => {
      Object.entries(initState).forEach(([key, value]) => {
        state[key] = value
      })
      state.logged = false
    }
  },

  actions: {
    getCompany({ commit }, data) {
      return getCompany(data)
        .then(res => {
          const data = res.data || {}
          commit('SET_USER', data)
          return res
        })
    },
    // 登录 msg
    login({ commit }, data) {
      return login(data)
        .then(res => {
          // debugger
          const data = res.data || {}
          commit('SET_USER', data)
          localStorage.setItem('token', data.token)
          storage.set('staff_info', data.staff_info)
          if (data.menu && data.menu.length > 0) {
            data.menu[0].redirect = '/overview'
            // let addobj = {
            //   component: "partnerMenuSettingAdd",
            //   icon: "meiye-routeicon-electronics",
            //   id: 23,
            //   title: "合伙人设置添加",
            //   path: "partnerMenuSettingAdd"
            // }
            //
            // data.menu[1].children[3].children[4]
            // data.menu[1].children[2].children[1].children = [addobj]
            // debugger
            // var a = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC96aGgubWVpeWUuY29tXC9hcGlcL3YyXC9hdXRoXC9hZG1pbl9zbXNfaW4iLCJpYXQiOjE2ODAzMTk5NjIsIm5iZiI6MTY4MDMxOTk2MiwianRpIjoidG1BVGhMS1dDZmZmOGFNbCIsInN1YiI6MjkxLCJwcnYiOiJkMjg0YTM4NWJkOTkwOThjYjBmNDVlZTU3NmNjYWEyODJjZjM3YjVlIn0.uOorsvk59dQ4Z4Cz8E9UJr-bm1duLusf92xK6IPg9hM'
            let Access = data.token.replace('Bearer ', '')
            // localStorage.setItem('Access-Token', Access)
            // let Access = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc2FuZGJveC5pbnRlcmZhY2UuY2MubWVpcmVuamkudmlwXC9hcGlcL3YxXC9hdXRoXC9hZG1pbl9sb2dpbiIsImlhdCI6MTY5NDM5NjEyNywiZXhwIjoxNjk0NDgyNTI3LCJuYmYiOjE2OTQzOTYxMjcsImp0aSI6ImRWcXJGbndWeU15VGpUa24iLCJzdWIiOjQyOCwicHJ2IjoiNTUwOTgxMmU1OTRiOWYyOWE1ODhkYzkxMmM4N2Y1YmExYjY3MzZjNCJ9.C29ukRh3s3LLOvIitPOoKfvB8ds7g19bb2hmQLY-pTk"
            localStorage.setItem('Access-Token', Access)
            storage.set('menu_base', data.menu)
            storage.set('staff_info', data.staff_info)
            return res
          } else {
            storage.set('menu_base', null)
          }

        })
    },
    // 登录 msg
    pslogin({ commit }, data) {
      return pslogin(data)
        .then(res => {
          const data = res.data || {}
          commit('SET_USER', data)
          localStorage.setItem('token', data.token)
          storage.set('staff_info', data.staff_info)
          if (data.menu && data.menu.length > 0) {
            data.menu[0].redirect = '/overview'
            let Access = data.token.replace('Bearer ', '')
            
            localStorage.setItem('Access-Token', Access)
            storage.set('menu_base', data.menu)
            storage.set('staff_info', data.staff_info)
            return res
          } else {
            storage.set('menu_base', null)
          }
        })
    },


    // 登出
    logout({ commit }, data) {
      return logout(data)
        .then(res => {
          localStorage.clear()
        })
    },
    // 获取用户信息
    queryUser({ commit }, data) {
      // return queryUser(data)
      // .then(res => {
      data = storage.get('staff_info') || {}
      commit('SET_USER', data)
      return data
      // })
    }
  }
}

export default user
